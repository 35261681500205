<template>
  <div class="row">
    <div class="col-12">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <ModalPDF ref="visorPDF"></ModalPDF>
      <Confirm ref="confirm"></Confirm>
      <ModalVideo ref="modalVideo"></ModalVideo>
      <transition name="slide">
        <div class="card border-warning">
          <div class="card-header">
            <strong>Historial Académico por Estudiante</strong>
            <div class=" card-header-actions">
              <form class="form-inline">
                <input id="user" type="hidden" value="<?php echo $user->id ?>"/>
                <b>Filas por p&aacute;gina</b>
                <select class="form-control mr-2 ml-1" @change="sizeChange($event)"
                        v-model="filasPorPagina">
                  <option v-for="option in sizeoptions" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>

                <button class="btn btn-outline-dark mr-2" type="button"
                        @click="borrarFiltroBtnOnClick()">
                  <font-awesome-icon icon="fa-sort-amount-up-alt"/>
                  Quitar Filtro
                </button>

                <button class="btn btn-outline-success ml-2" data-html="true"
                        data-toggle="tooltip"
                        title="Para exportar todos los datos en un archivo Excel<br/>seleccione la opci&oacute;n <u>Todos</u> en <b>Filas por p&aacute;gina</b>"
                        type="button"
                        @click="excelBtnOnClick()">
                  <font-awesome-icon icon="fa-file-excel"/>
                  Exportar a Excel
                </button>


                <CDropdown
                    inNav
                    class="c-header-nav-items"
                    placement="bottom-end"
                    add-menu-classes="pt-0"
                    :caret="false"
                >
                  <template #toggler>
                    <CHeaderNavLink title="Ver videos de ayuda">
                      <font-awesome-icon icon="fas fa-question-circle" size="2x"/>
                    </CHeaderNavLink>
                  </template>
                  <CDropdownItem>
                    <a href="#" @click="$refs.modalVideo.mostrarVideo('video-alumno-hoja-ruta.mp4')"
                       class="text-info" title="Ver video de ayuda">
                      <font-awesome-icon icon="fas fa-video"/>
                      Ayuda (Hoja ruta)
                    </a>
                  </CDropdownItem>
                  <CDropdownItem>
                    <a href="#" @click="$refs.modalVideo.mostrarVideo('video-alumno-eliminar-nuevo.mp4')"
                       class="text text-danger" title="Ver video de ayuda">
                      <font-awesome-icon icon="fas fa-video"/>
                      Ayuda (Eliminar)
                    </a>
                  </CDropdownItem>
                </CDropdown>
              </form>
            </div>
          </div>
          <div class="card-body p-0 pr-1">
            <JqxGrid :width="'100%'" ref="gridSystem"
                     :source="dataAdapter"
                     :autoheight="true" :autorowheight="false" :rowsheight="50"
                     :pageable="true"
                     :pagesize="parseInt(filasPorPagina)"
                     :virtualmode="true"
                     :rendergridrows="rendergridrows"
                     :columns="columns" :enablebrowserselection="true"
                     :enabletooltips="true" :filterable="true"
                     :showfilterrow="true"
                     :sortable="true"
                     :pagermode="'simple'" :localization="localization"
                     :theme="'bootstrap'" :columnsresize="true"
                     :showcolumnlines="false"
                     :showcolumnheaderlines="false"
            />
          </div>
        </div>
      </transition>
      <div class="card border-warning mt-3" v-if="modalHistorial">
        <div class="card-header text-center">
          <div class="row">
            <div class="col-2"></div>
            <div class="col text-center"><h4>{{ alumno }}</h4></div>
            <div class="col-2 text-end text-right">
              <button class="btn btn-primary" @click="nuevaMateria">
                Nueva Materia
              </button>
            </div>
          </div>
        </div>
        <div class="card-body p-0 pr-1 pe-1">
          <JqxGrid :width="'100%'" ref="gridSystemhistorial"
                   :source="dataAdapterhistorial"
                   :autoheight="true" :autorowheight="true"
                   :pageable="true"
                   :pagesize="100"
                   :virtualmode="true"
                   :rendergridrows="rendergridrowshistorial"
                   :columns="columnshistorial" :enablebrowserselection="true"
                   :enabletooltips="true" :filterable="true"
                   :showfilterrow="true"
                   :sortable="true"
                   :pagermode="'simple'" :localization="localization"
                   :theme="'bootstrap'" :columnsresize="true"/>
        </div>
      </div>
    </div>
    <CModal
        :centered="true"
        :closeOnBackdrop="false"
        :fade="true"
        :no-close-on-backdrop="true"
        :show.sync="modalHistorialEditar"
        size="xl"
    >
      <template #body-wrapper>
        <table class="table table-sm table-bordered">
          <tr>
            <th style="width: 14%">Gestión</th>
            <th style="width: 8%">Grupo</th>
            <th style="width: 8%">Semestre</th>
            <th style="">Materia</th>
            <th style="width: 5%">Parcial 1</th>
            <th style="width: 5%">Parcial 2</th>
            <th style="width: 5%">Parcial 3</th>
            <th style="width: 5%">Examen final</th>
            <th style="width: 5%">Nota Final</th>
            <th style="width: 5%">Segunda Instancia</th>
          </tr>
          <tr :class="materia.estado=='APROBADO'?'':'text-danger'">
            <td>
              <select v-model="materia.gestion" class="form-control px-0">
                <template v-for="(option,key) in gestiones">
                  <optgroup :label="key">
                    <option v-for="suboption in option" :value="suboption.value">
                      {{ suboption.label }}
                    </option>
                  </optgroup>
                </template>
              </select>
            </td>
            <td>
              <select v-model="materia.grupo" class="form-control px-0 text-center">
                <option v-for="option2 in grupos" :value="option2">
                  {{ option2 }}
                </option>
              </select>
            </td>
            <td class="text-center">
              <template v-if="materia.id>0">
                {{ materia.semestre}}
              </template>
              <select v-else class="form-control px-0" id="semeste"
                      v-model="materia.semestre" required="required"
                      @change="materia.materia_id=0"
              >
                <option value="0" selected>:: SELECCIONAR ::</option>
                <option v-for="(option,key) in semestres" :value="option">
                  {{ option }}
                </option>
              </select>
            </td>
            <td>
              <template v-if="materia.id>0">
                {{ materia.sigla }} - {{ materia.materia }}
              </template>
              <select v-else id="materia" required="required"
                      v-model="materia.materia_id" class="form-control">
                <option value="0">:: SELECCIONAR ::</option>
                <option v-for="(option,key) in materias[materia.semestre]" :value="key">{{ option.materia }}
                </option>
              </select>
            </td>
            <td class="text-center"><input type="number" min="0" max="100" v-model="materia.parcial1" class="form-control"></td>
            <td class="text-center"><input type="number" min="0" max="100" v-model="materia.parcial2" class="form-control"></td>
            <td class="text-center"><input type="number" min="0" max="100" v-model="materia.parcial3" class="form-control"></td>
            <td class="text-center"><input type="number" min="0" max="100" v-model="materia.examen_final" class="form-control"></td>
            <td class="text-center pt-2">{{ materia.nota_final }}</td>
            <td class="text-center"><input type="number" min="0" max="100" v-model="materia.seg_instancia" class="form-control"></td>
          </tr>
        </table>
        <table class="table table-sm table-bordered">
          <tr>
            <th style="width: 30%">Convalidación</th>
            <th style="width: 35%">Observaciones</th>
            <th style="width: 35%">Universidad de Origen</th>
          </tr>
          <tr :class="materia.estado=='APROBADO'?'':'text-danger'">
            <td class="text-center">
              <select v-model="materia.convalidacion" class="form-control px-0">
                <option v-for="option2 in opcion_convalidacion" :value="option2.value">
                  {{ option2.text }}
                </option>
              </select>
            </td>
            <td>
              <textarea v-model="materia.observacion" class="form-control px-0" rows="2"
                        @change="materia.observacion=materia.observacion.toUpperCase()"></textarea>
            </td>
            <td>
              <textarea v-model="materia.universidad_convalidacion" class="form-control px-0" rows="2"
                        @change="materia.universidad_convalidacion=materia.universidad_convalidacion.toUpperCase()"></textarea>
            </td>
          </tr>
        </table>
      </template>
      <template #header>
        <h4>{{ alumno }}</h4>
        <CButtonClose @click="modalHistorialEditar = false;"/>
      </template>
      <template #footer-wrapper>
        <div class="row">
          <div class="col-12 text-center p-2">
            <button type="button" class="btn btn-success mx-4" @click="historialgrabar">Grabar</button>
            <button type="button" class="btn btn-danger" @click="modalHistorialEditar = false;">Cerrar (sin guardar cambios)</button>
          </div>
        </div>
      </template>
    </CModal>
    <CModal
        :centered="true"
        :closeOnBackdrop="false"
        :fade="true"
        :no-close-on-backdrop="true"
        :show.sync="modalHistorialPdf"
        size="xl"
    >
      <template #body-wrapper>
        <div class="row pl-2">
          <div class="col-12">
            <embed ref="reportePdf" height="500" src="" type="application/pdf" title="pdf.pdf"
                   width="100%"/>
          </div>
        </div>
      </template>
      <template #header>
        <h4>{{ alumno }}</h4>
        <CButtonClose @click="modalHistorialPdf = false"/>
      </template>
      <template #footer-wrapper><span></span></template>
    </CModal>
  </div>
</template>
<script>
import JqxGrid from "jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid";
import jqxInput from "jqwidgets-scripts/jqwidgets-vue/vue_jqxinput";
import Toast from "@/views/notifications/Toast";
import Alerts from "@/views/notifications/Alerts";
import ModalPDF from "@/views/notifications/ModalPDF";
import Confirm from "@/views/notifications/Confirm";
import ModalVideo from "@/views/notifications/ModalVideo";
import axios from "axios";

var sourceLst;
var sourceLstHistorial;
var address;
var modalPdf;
var pagesizeoptions = [3, 6, 10, 50, 100, 500, 1000, 5000, 10000];
var pagesize = pagesizeoptions[0];

export default {
  name: 'Alumnos',
  components: {
    ModalVideo,
    Confirm,
    ModalPDF,
    Alerts,
    Toast,
    JqxGrid,
    jqxInput
  },
  data: () => {
    return {
      darkModal: false,
      modalHistorial: false,
      modalHistorialEditar: false,
      modalHistorialPdf: false,
      historiales: [],
      gestiones: [],
      semestres:[],
      materias:[],
      grupos: [],
      codigo_alumno: '',
      alumno: '',
      historialseleccionado: '',
      historialaprobadas: 0,
      historialmaterias: 0,
      factura_id:0,
      items: [],
      currentPage: 1,
      perPage: 7,
      totalRows: 0,
      filasPorPagina: pagesizeoptions[0],
      sizeoptions: [],
      materia: [],
      materiaNueva: {
        id:0,
        gestion:"",
        semestre:0,
        grupo:"",
        sigla:"",
        materia:"",
        parcial1:0,
        parcial2:0,
        parcial3:0,
        examen_final:0,
        nota_final:0,
        seg_instancia:0,
        convalidacion: "N",
        estado:"NO",
        observacion:"",
        universidad_convalidacion:"",
      },
      sedeSeleccionada: localStorage.sedeSeleccionada,

      dataAdapterhistorial: new jqx.dataAdapter(sourceLstHistorial, {
        loadError: function (xhr, status, error) {
          console.log("Error ocurrido al obtener los datos del servidor, " + status + ", " + error);
          location.href = location.protocol + "//" + location.host + "/#/login";
        }
      }),
      rendergridrowshistorial: (params) => {
        return params.data;
      },
      columnshistorial: [
        {
          text: 'gestion', datafield: "gestion", width: '5%', cellsalign: 'center',sortable: false,
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Gestión</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'Grupo', datafield: "grupo", width: '4%', cellsalign: 'center',sortable: false,
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Grupo</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'Semestre', datafield: "semestre", width: '4%', cellsalign: 'center',sortable: false,
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Semes<br/>tre</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'Sigla', datafield: "sigla", width: '6%',cellsalign: 'center',sortable: false,
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'Materia', datafield: "materia", width: '22%',sortable: false,
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'parcial1', datafield: "parcial1", width: '4%', cellsalign: 'center',sortable: false,
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Parcial<br/>1</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'parcial2', datafield: "parcial2", width: '4%', cellsalign: 'center',sortable: false,
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Parcial<br/>2</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'parcial3', datafield: "parcial3", width: '4%', cellsalign: 'center',sortable: false,
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Parcial<br/>3</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'examen_final', datafield: "examen_final", width: '5%', cellsalign: 'center',sortable: false,
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Examen<br/>Final</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'Nota Final', datafield: "nota_final", width: '5%', cellsalign: 'center',sortable: false,
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Nota<br/>Final</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'seg_instancia', datafield: "seg_instancia", width: '5%',cellsalign: 'center',sortable: false,
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Segunda<br/>Instancia</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'convalidacion', datafield: "convalidacion", width: '5%',cellsalign: 'center',sortable: false,
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Convali-<br/>dación</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          }
        },
        {
          text: 'Estado', datafield: "estado", width: '7%',cellsalign: 'center',sortable: false,
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          },
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return '<div style="margin-top: 5px; margin-left: 5px;" '+(value == 'REPROBADO' ? 'class="text-danger"':'')+'>'+rowdata.estado+'</div>';
          },
        },
        {
          text: 'Obs.', datafield: "observacion", width: '5%',sortable: false,
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          },
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return '<div style="margin-top: 5px; margin-left: 5px;"><small>'+rowdata.observacion+'</small></div>';
          },
        },
        {
          text: 'Obs.', datafield: "universidad_convalidacion", width: '5%',sortable: false,
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar"});
          },
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Universidad de<br/>Origen</div>';
          },
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return '<div style="margin-top: 5px; margin-left: 5px;"><small>'+rowdata.universidad_convalidacion+'</small></div>';
          },
        },
        {
          text: "Editar", datafield: '', width: '5%', columntype: "button",
          filterable: false, sortable: false,
          cellclassname: function(row, columnfield, value) {
            let rowData = modalPdf.$refs.gridSystemhistorial.getrowdata(row);
            //if(rowData.estado == 'APROBADO') {
            if(rowData.nota_final >= 51 || rowData.seg_instancia >= 51) {
              return 'btn-green';
            } else {
              return 'btn-orange';
            }
          },
          renderer: function () {
            return '';
          },
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return 'Editar';
          },
          buttonclick: function (row, event) {
            let button = $(event.currentTarget);
            let grid = button.parents("[id^=jqxGrid]");
            let rowData = grid.jqxGrid('getrowdata', row);
            modalPdf.materia=rowData;
            modalPdf.modalHistorialEditar = true;
          }
        },
        {
          text: "Eliminar", datafield: 'eliminar', width: '5%', columntype: "button",
          filterable: false, sortable: false,
          cellclassname: 'btn-red',
          renderer: function () {
            return '';
          },
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return 'Eliminar';
          },
          buttonclick: function (row, event) {
            let button = $(event.currentTarget);
            let grid = button.parents("[id^=jqxGrid]");
            var rowData = grid.jqxGrid('getrowdata', row);
            modalPdf.$refs.confirm.confirm('¿Eliminar este regsitro?','',function () {
              axios.post(
                  address + '/api/docentereportes/' + modalPdf.codigo_alumno + '/historialeliminar?token=' + localStorage.getItem("api_token"),
                  {'id':rowData.id}
              )
                  .then((response) => {
                    modalPdf.$refs.gridSystemhistorial.updatebounddata();
                    modalPdf.$refs.mensajeToast.makeToast('', 'Registro Eliminado','success');
                  })
                  .catch(function (error) {
                    modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
                  })
            });
          }
        },
      ],

      dataAdapter: new jqx.dataAdapter(sourceLst, {
        loadError: function (xhr, status, error) {
          console.log("Error ocurrido al obtener los datos del servidor, " + status + ", " + error);
          location.href = location.protocol + "//" + location.host + "/#/login";
        }
      }),
      rendergridrows: (params) => {
        return params.data;
      },
      columns: [
        {
          text: 'Código Alumno', datafield: "codigo_alumno", width: '7%', cellsalign: 'center',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Código<br />Alumno</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Código Alumno"});
          }
        },
        {
          text: 'Apellido1', datafield: "apellido1", width: '14%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Primer<br />apellido</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Apellido1"});
          }
        },
        {
          text: 'Apellido2', datafield: "apellido2", width: '14%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Segundo<br />apellido</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Apellido2"});
          }
        },
        {
          text: 'Nombres', datafield: "nombres", width: '15%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Nombres"});
          }
        },
        {
          text: 'Carrera', datafield: "carrera", width: '23%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Carrera"});
          }
        },
        {
          text: "", datafield: 'hojaruta', width: '9%', columntype: "button",
          filterable: false, sortable: false,
          cellclassname: "btn-green",
          renderer: function () {
            return '';
          },
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return ((rowdata.estado === 'Y') ? "Seleccionar" : "...");
          },
          buttonclick: function (row, event) {
            let button = $(event.currentTarget);
            let grid = button.parents("[id^=jqxGrid]");
            let rowData = grid.jqxGrid('getrowdata', row);
            //modalPdf.$refs.reportePdf.setAttribute('src', '')
            modalPdf.codigo_alumno = rowData.codigo_alumno;
            modalPdf.alumno = rowData.nombres + ' ' + rowData.apellido1 + ' ' + rowData.apellido2;
            modalPdf.modalHistorial = true;
            modalPdf.gestionseleccionada = ''
            sourceLstHistorial.url = address + '/api/docentereportes/' + rowData.codigo_alumno + '/listarhistorial?token=' + localStorage.getItem("api_token")
            try {
              modalPdf.$refs.gridSystemhistorial.updatebounddata();
            }catch (e) {
              
            }
          }
        },
        {
          text: "", width: '18%', columntype: "button",
          filterable: false, sortable: false,
          cellclassname: "btn-orange",
          renderer: function () {
            return '';
          },
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return ((rowdata.estado === 'Y') ? "Imprimir Historial Académico" : "...");
          },
          buttonclick: function (row, event) {
            let button = $(event.currentTarget);
            let grid = button.parents("[id^=jqxGrid]");
            let rowData = grid.jqxGrid('getrowdata', row);
            modalPdf.$refs.reportePdf.setAttribute('src', '')
            modalPdf.codigo_alumno = rowData.codigo_alumno;
            modalPdf.alumno = rowData.nombres + ' ' + rowData.apellido1 + ' ' + rowData.apellido2;

            modalPdf.$refs.reportePdf.setAttribute('src', '')
            modalPdf.$refs.alert.show('Descargando documento');
            let serie=0
            axios.get(
                address + '/api/docentereportes/' + modalPdf.codigo_alumno + '/historialacademicopdfsinfactura?token=' + localStorage.getItem("api_token"),
                {responseType: 'blob'}
            )
                .then((response) => {
                  modalPdf.$refs.reportePdf.setAttribute('src', URL.createObjectURL(response.data) + "#view=FitH");
                })
                .catch(function (error) {
                  modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
                })
                .finally(function () {
                  modalPdf.$refs.alert.hide();
                  modalPdf.modalHistorialPdf = true;
                })
          }
        },
      ],
      localization: {
        'emptydatastring': "No existen datos para esta SEDE",
      },
    }
  },
  beforeCreate: function () {
    const url = this.$apiAdress + '/api/docentereportes/boletin?token=' + localStorage.getItem("api_token") + '&sede_id=' + localStorage.sedeSeleccionada;
    address = this.$apiAdress;
    sourceLst = {
      url: url,
      datafields: [
        {name: 'id', type: "int"},
        {name: 'codigo_alumno', type: "string"},
        {name: 'apellido1', type: "string"},
        {name: 'apellido2', type: "string"},
        {name: 'nombres', type: "string"},
        {name: 'carrera', type: "string"},
        {name: 'serie', type: "string"},
        {name: 'estado', type: "string"},
      ],
      data: {
        sede: this.sede
      },
      datatype: "json",
      root: "Rows",
      id: 'codigo_alumno',
      sortcolumn: 'codigo_alumno',
      sortdirection: 'desc',
      opcion_convalidacion:[],
      cache: false,
      beforeprocessing: (data) => {
        if (data != null) {
          sourceLst.totalrecords = data[0].TotalRows;
          let i = 0;
          this.sizeoptions = [];
          while (i < 8 && pagesizeoptions[i] < data[0].TotalRows) {
            this.sizeoptions[i] = {text: pagesizeoptions[i], value: pagesizeoptions[i]};
            i++;
          }
          if (i > 1) {
            this.sizeoptions[i] = {text: "TODOS", value: data[0].TotalRows};
          }
        }
        modalPdf.$refs.alert.hide();
      },
      filter: () => {
        this.$refs.gridSystem.updatebounddata("filter");
      },
      sort: () => {
        this.$refs.gridSystem.updatebounddata("sort");
      },
    };

    sourceLstHistorial = {
      url: '',
      datafields: [
        {name: 'id', type: "int"},
        {name: 'orden', type: "string"},
        {name: 'gestion', type: "string"},
        {name: 'grupo', type: "string"},
        {name: 'semestre', type: "int"},
        {name: 'materia_id', type: "int"},
        {name: 'sigla', type: "string"},
        {name: 'materia', type: "string"},
        {name: 'parcial1', type: "float"},
        {name: 'parcial2', type: "float"},
        {name: 'parcial3', type: "float"},
        {name: 'examen_final', type: "float"},
        {name: 'nota_final', type: "float"},
        {name: 'seg_instancia', type: "float"},
        {name: 'convalidacion', type: "string"},
        {name: 'estado', type: "string"},
        {name: 'observacion', type: "string"},
        {name: 'universidad_convalidacion', type: "string"},
      ],
      data: {
        sede: this.sede
      },
      datatype: "json",
      root: "Rows",
      id: 'id',
      sortcolumn: 'orden',
      sortdirection: 'asc',
      cache: false,
      beforeprocessing: (data) => {
        if (data != null) {
          sourceLstHistorial.totalrecords = data[0].TotalRows;

        }
        modalPdf.$refs.alert.hide();
      },
      filter: () => {
        this.$refs.gridSystemhistorial.updatebounddata("filter");
      },
      sort: () => {
        this.$refs.gridSystemhistorial.updatebounddata("sort");
      },
    };
  },
  methods: {
    hasRole(roleUser) {
      let roles = localStorage.getItem("roles");
      if (roles != null) {
        roles = roles.split(',')
        if (roles != null && roles.indexOf(roleUser) >= 0) {
          return true
        }
      }
      return false;
    },
    showHideColumn(role) {
      try {
        if (!modalPdf.hasRole(role)) {
          this.$refs.gridSystem.hidecolumn(role);
        }
      } catch (e) {
      }
    },
    excelBtnOnClick: function () {
      this.$refs.gridSystem.hidecolumn('documentos');
      this.$refs.gridSystem.hidecolumn('editar');
      this.$refs.gridSystem.hidecolumn('eliminar');
      let date = new Date();
      let dateStr =
          date.getFullYear() + "" +
          ("00" + (date.getMonth() + 1)).slice(-2) + "" +
          ("00" + date.getDate()).slice(-2) + "_" +
          ("00" + date.getHours()).slice(-2) + "" +
          ("00" + date.getMinutes()).slice(-2) + "" +
          ("00" + date.getSeconds()).slice(-2);
      this.$refs.gridSystem.exportdata('xls', 'Inscritos' + dateStr, true, null, true, modalPdf.$apiAdress + "/api/reporte/exportarxls/" + '?token=' + localStorage.getItem("api_token"));
      this.$refs.gridSystem.showcolumn('documentos');
      this.$refs.gridSystem.showcolumn('editar');
      this.$refs.gridSystem.showcolumn('eliminar');
    },
    borrarFiltroBtnOnClick: function () {
      this.$refs.gridSystem.clearfilters();
    },
    sizeChange: function (event) {
      try {
        modalPdf.$refs.alert.show("Actualizando datos");
        this.$refs.gridSystem.pagesize = parseInt(this.filasPorPagina);
        this.$refs.gridSystem.updatebounddata();
      } catch (e) {}
    },
    listarhistoriales: function (codigo_alumno) {
      sourceLstHistorial.url = address + '/api/docentereportes/' + codigo_alumno + '/historiales?token=' + localStorage.getItem("api_token")
      this.$refs.gridSystemhistorial.updatebounddata();
    },
    mostrarHistorial: function (historial) {
      if (historial !== '') {
        let serie = historial.serie
        modalPdf.historialaprobadas = historial.materiasaprobadas[0].aprobadas
        modalPdf.historialmaterias = historial.materiasaprobadas[0].materias
        modalPdf.mostrarCrearHistorial(serie)
      }
    },
    historialgrabar: function () {
      modalPdf.modalHistorialEditar = false;
      modalPdf.$refs.alert.show('Grabando...');
        axios.post(
            address + '/api/docentereportes/' + modalPdf.codigo_alumno + '/historialgrabar?token=' + localStorage.getItem("api_token"),
            modalPdf.materia
        )
            .then((response) => {
              modalPdf.$refs.gridSystemhistorial.updatebounddata();
              modalPdf.modalHistorialEditar = false;
            })
            .catch(function (error) {
              modalPdf.modalHistorialEditar = true;
              modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
            })
            .finally(function () {
              modalPdf.$refs.alert.hide();
            })
    },
    nuevaMateria: function (){
      modalPdf.materia=this.materiaNueva;
      modalPdf.modalHistorialEditar=true
      axios.get(modalPdf.$apiAdress + '/api/docentereportes/' + modalPdf.codigo_alumno + '/listarmaterias?token=' + localStorage.getItem("api_token"))
          .then(function (response) {
            if (Object.keys(response.data.semestres).length > 1) {
              console.log(response.data.semestres)
              modalPdf.semestres = response.data.semestres;
              modalPdf.materias = response.data.materias;
            } else {
              modalPdf.planes = response.data.planes;
            }
            modalPdf.plan_id = response.data.planes[0].value
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    }
  },
  mounted() {
    modalPdf = this;
    this.$root.$on('sedeSeleccionada', data => {
      left.sede = data;
    });
    axios.get(
        address + '/api/docentereportes/0/historialparametros?token=' + localStorage.getItem("api_token")
    )
        .then((response) => {
          modalPdf.gestiones=response.data.gestiones;
          modalPdf.grupos=response.data.grupos;
          modalPdf.opcion_convalidacion=response.data.opcion_convalidacion;
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
        })
    modalPdf.showHideColumn('documentos')
    modalPdf.showHideColumn('compromisos');
    modalPdf.showHideColumn('contratos');
    modalPdf.showHideColumn('hojaruta');
  },
}
</script>